/* This Component is a route view for display a form when build */
<template>
  <div class="preview-form">
      <div class="preview-form-wraper">
          <BuilderPreview v-if="!error" :is_preview="true"/>
          <h1 v-else>אין טופס לתצוגה</h1>
      </div>
  </div>
</template>

<script>
import BuilderPreview from '../BuilderPreview.vue'
import {reload_temporary_data_form_from_db} from '../../../../../../../Scripts/Forms_builder'
import { ref } from '@vue/reactivity'
export default {
    components:{BuilderPreview},
    setup(){
        const error = ref(false)
        const init = async()=>{
            try{
                await reload_temporary_data_form_from_db()
            }catch(err){
                error.value = true
            }
        }

        init()
        return{error}
    }
}
</script>

<style scoped>
    .preview-form{
        width: 100%;
        height: 100%;
        background:#fff;
        overflow-y: auto;
    }
    .preview-form-wraper{
        padding: 10px 0;
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
        height: fit-content;
        background: whitesmoke;
    }
</style>